import LayoutRoot from '../components/LayoutRoot'
import BannerImage_00 from '../images/banner_00.jpg'
import BannerImage_01 from '../images/banner_01.jpg'
import BannerImage_02 from '../images/banner_02.jpg'
import BannerImage_03 from '../images/banner_03.jpg'
import BannerImage_04 from '../images/banner_04.jpg'
import BannerImage_05 from '../images/banner_05.jpg'
import BannerImage_06 from '../images/banner_06.jpg'
import BannerImage_07 from '../images/banner_07.jpg'
import * as styles from './../styles/banner.module.scss'

const BannerPage = () => (
  <LayoutRoot>
    <div className={styles.div} style={{ margin: 'auto' }}>
      <img className={styles.bannerImageItem} src={BannerImage_00} alt="banner_00.jpg" />
      <img className={styles.bannerImageItem} src={BannerImage_01} alt="banner_01.jpg" />
      <img className={styles.bannerImageItem} src={BannerImage_02} alt="banner_02.jpg" />
      <img className={styles.bannerImageItem} src={BannerImage_03} alt="banner_03.jpg" />
      <img className={styles.bannerImageItem} src={BannerImage_04} alt="banner_04.jpg" />
      <img className={styles.bannerImageItem} src={BannerImage_05} alt="banner_05.jpg" />
      <img className={styles.bannerImageItem} src={BannerImage_06} alt="banner_06.jpg" />
      <img className={styles.bannerImageItem} src={BannerImage_07} alt="banner_07.jpg" />
    </div>
  </LayoutRoot>
)

export default BannerPage
